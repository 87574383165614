<template>
    <Index version="2.3.4"/>
    <router-view />
</template>

<script>
    import Index from '@/components/index.vue'
    import { mapMutations } from 'vuex'
    export default {
        name: 'Index-Home',
        components: {
            Index
        },
        props:{
            version: String,
            user: String,
            email: String,
            accountID: String,
            alerts: String
        },
        mounted(){
            this.setSidebarHidden(true)
        },
        methods:{
            ...mapMutations(['setSidebarHidden'])
        } 
        
    }
</script>

<style scoped>
    #dashboard_menu,#dashboard_info{
        display: none;
    }
</style>