<template>
  <Header version="2.3.4" />
  <div id="wrapper">
    <Sidebar version="2.3.4"></Sidebar>
    <router-view />
  </div>
  <Footer></Footer>
</template>

<script>
    import Header from '@/components/header.vue'
    import Sidebar from '@/components/sidebar.vue'
    import Footer from '@/components/footer.vue'
    import store from '@/store'
    import { mapState } from 'vuex'

    // function getCookie(name) {
    //   const value = `; ${document.cookie}`;
    //   const parts = value.split(`; ${name}=`);
    //   if (parts.length === 2) return parts.pop().split(';').shift();
    // }

    export default {
      name: 'App',
      components: {
        Header,
        Sidebar,
        Footer
      },
      data: () => ({
        user:'Joe', 
        last_name:'Astrahan', 
        email:'astrahanjoe@gmail.com',
        accountID: '1551-1443-1444-1144-2444'
      }),
      props: {
        version: {type: String, default: "2.3.3"},
      },
      computed: {
        ...mapState(['sidebarHidden']),
      },
      methods: {
        async get_session(){
          var response;
          try{
            response = await store.dispatch('getSession');
          }catch(error){
            console.log("Get session failed");
            console.error(error);
          }
          console.log('App Response from call to getSession below');
          console.log(response);
        }
      },
      mounted(){
        console.log("App mounted");
        this.get_session();
        
        setInterval(() => {
          if (!this.sidebarHidden){
            this.get_session()
          }
        }, 5000); 
      }
    }
</script>

<style>
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
      display: block;
    }
    body {
      line-height: 1;
    }
    ol, ul {
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    :root,::after,::before{
      --nav-height: 68px;
      --nav-sidebar-width: 180px;
      --footer-height: 30px;
      --color-1: red;
    }
    * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
    #wrapper{
      display: flex;
      flex-direction: column;
      padding-top: var(--nav-height);
      min-height:calc(100% - var(--footer-height));
    }
    #app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    /*color: #2c3e50;*/
    }

    html {
      margin: 0;
      padding: 0;
      display: table;
      width:100%;
      height:100%;
    }
    body {
      display: table-cell;
      width: 100%;
      color: #000;
      font-size: 16px;
      margin: 0;
      padding: 0;
      font-family: Ubuntu, Helvetica, Arial, sans-serif;
    }
    #dashboard_menu{
      display: flex;
      align-items: flex-start;
    }
    #dashboard_info{
      display: flex;
      align-items: flex-end;
    }
</style>